import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { IComanda } from '../../commons/types/crm';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

export const columnsAssessorados: GridColDef[] = [
  {
    field: 'contato.nome',
    headerName: 'Assessorado',
    width: 160,
    flex: 160,
    valueGetter(params: any, row: IComanda) {
      const comanda = row;
      return comanda.contato?.nome;
    }
  },
  {
    field: 'modeloComercial',
    headerName: 'Modelo Comercial',
    width: 160,
    flex: 160,
  },
  {
    field: 'dataInicio',
    headerName: 'Data Início',
    width: 160,
    flex: 160,
    valueFormatter(params: any) {
      return moment(params.value).format('DD/MM/YYYY')
    },
  },
  {
    field: 'dataFim',
    headerName: 'Data Fim',
    width: 160,
    flex: 160,
    valueFormatter(params: any) {
      return moment(params.value).format('DD/MM/YYYY')
    },
  },
  {
    field: '',
    headerName: 'Ações',
    width: 120,
    editable: false,
    renderCell: (params: GridCellParams) => {
      let comanda = params.row as IComanda;
      return (
        <>
          <Link
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              paddingRight: '8px',
              color: 'rgb(70, 70, 70)',
            }}
            to={'/assessorados/' + comanda.id}
          >
            <EditIcon />
          </Link>
        </>
      );
    },
  },
]
