import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { useComandaActions } from '../../states/comandas/comanda.actions';
import { useEffect, useState } from 'react';
import { IComanda } from '../../commons/types/crm';
import { toast } from 'react-toastify';
import { PaperReunioes } from '../Reunioes';
import { DataGrid } from '@mui/x-data-grid';
import { columnsAssessorados } from './columns';
import { useRecoilState } from 'recoil';
import { comandaAtom } from '../../states/comandas/ComandaState';
import { ptBR } from '@mui/material/locale';

const Assessorados = () => {
  const comandaActions = useComandaActions();
  const [comandaState, setComandaState] = useRecoilState(comandaAtom);
  const [comandas, setComandas] = useState<IComanda[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    comandaActions
      .list()
      .then((data) => {
        setComandaState(data.resultado as IComanda[]);
        setComandas(data.resultado as IComanda[]);
      })
      .catch((error) => toast.error(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <PaperReunioes elevation={3}>
      <Typography variant='h4'>Comandas Ativas</Typography>
      <br />
      <ThemeProvider theme={createTheme({}, ptBR)}>
        <DataGrid
          rows={comandas}
          columns={columnsAssessorados}
          loading={loading}
          sx={{
            borderRadius: '10px',
            minHeight: '700px',
            height: 'auto',
          }}
        />
      </ThemeProvider>
    </PaperReunioes>
  );
};

export default Assessorados;
