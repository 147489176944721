import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { ptBR } from '@mui/material/locale';
import { IReuniao, StatusReuniaoEnum } from "../../commons/types/crm";
import moment from "moment";
import { Paper, ThemeProvider, Typography, createTheme } from "@mui/material";
import { AcoesColumn } from "./acoesColumn";
import { useState } from "react";
import DrawerConcluir, { IDrawerConcluir } from "./Components/DrawerConcluir";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../states/usuarios/AuthState";

interface ITableReunioes {
  reunioes: IReuniao[];
  loading: boolean;
}

export const handleStatus = (status: StatusReuniaoEnum) => {
  switch (status) {
    case StatusReuniaoEnum.PENDENTE:
      return "Pendente Agendamento";
    case StatusReuniaoEnum.AGENDADA:
      return "Agendada";
    case StatusReuniaoEnum.PENDENTE_APROVACAO:
      return "Aguardando Aprovação";
    case StatusReuniaoEnum.APROVADA:
      return "Aprovada";
    case StatusReuniaoEnum.CANCELADA:
      return "Cancelada";
    case StatusReuniaoEnum.NO_SHOW:
      return "Não Compareceu";
    default:
      break;
  }
};

export const columns: (args: IDrawerConcluir) => GridColDef[] = (
  args: IDrawerConcluir,
) => [
    {
      field: "nomeAssessorado",
      headerName: "Assessorado",
      width: 130,
      flex: 130,
    },
    {
      field: "nomeEtapa",
      headerName: "Etapa",
      width: 120,
      flex: 120,
    },
    {
      field: "data",
      headerName: "Data",
      width: 100,
      valueGetter(value: any, row: IReuniao) {
        const reuniao = row as IReuniao;
        if (
          reuniao.status === StatusReuniaoEnum.PENDENTE_APROVACAO ||
          reuniao.status === StatusReuniaoEnum.APROVADA
        )
          return moment(reuniao.dtRealizadaInicio).format("DD/MM");
        return moment(reuniao.dtPrevista).format("DD/MM");
      },
    },
    {
      field: "horaInicio",
      headerName: "Início",
      width: 100,
      valueGetter(params: any, row: IReuniao) {
        const reuniao = row;
        return moment(reuniao.dtRealizadaInicio).format("hh:mm");
      },
    },
    {
      field: "horaFim",
      headerName: "Fim",
      width: 100,
      valueGetter(params: any, row: IReuniao) {
        const reuniao = row;
        return moment(reuniao.dtRealizadaFim).format("hh:mm");
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      // maxWidth: 190,
      flex: 120,
      valueGetter(params: any, row: IReuniao) {
        const reuniao = row;
        return handleStatus(reuniao.status);
      },
    },
    {
      field: "",
      headerName: "Ações",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <AcoesColumn
          row={params.row}
          setOpen={args.setOpen}
          setReuniao={args.setReuniao!}
        />
      ),
    },
  ];

const TableReunioes: React.FC<ITableReunioes> = (props) => {
  const { reunioes, loading } = props;
  const [open, setOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [reuniao, setReuniao] = useState<IReuniao>();
  const auth = useRecoilValue(authAtom);

  return (
    <>
      <Paper sx={{ padding: "30px", borderRadius: "10px" }} elevation={3}>
        <Typography variant="h3">Reuniões: </Typography>

        <ThemeProvider theme={createTheme({}, ptBR)}>
          <DataGrid
            rows={reunioes.filter((r: IReuniao) => r.idConsultor == auth?.id)}
            loading={loading}
            columns={columns({
              open,
              setOpen,
              loadingSave,
              setLoadingSave,
              setReuniao,
            })}
            sx={{
              borderRadius: "10px",
              minHeight: "319px",
              marginTop: "20px",
              height: "auto",
            }}
          />
        </ThemeProvider>
      </Paper>

      {reuniao && (
        <DrawerConcluir
          reuniao={reuniao}
          open={open}
          setOpen={setOpen}
          loadingSave={loadingSave}
          setLoadingSave={setLoadingSave}
        />
      )}
    </>
  );
};

export default TableReunioes;
