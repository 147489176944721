import { useEffect, useState } from 'react';
import { IReuniao } from '../../commons/types/crm';
import { Paper, createTheme } from '@mui/material';
import styled, { ThemeProvider } from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';
import { ptBR } from '@mui/material/locale';
import { columns } from '../Dashboard/tableReunioes';
import { useReuniaoActions } from '../../states/reunioes/reuniao.actions';
import { toast } from 'react-toastify';
import DrawerConcluir from '../Dashboard/Components/DrawerConcluir';

const Reunioes: React.FC = () => {
  const [reunioes, setReunioes] = useState<IReuniao[]>([]);
  const [open, setOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const reuniaoActions = useReuniaoActions();
  const [reuniao, setReuniao] = useState<IReuniao>();

  useEffect(() => {
    reuniaoActions
      .list()
      .then((resp: any) => setReunioes(resp.resultado))
      .catch((e) => toast.error(e));
  }, []);

  return (
    <PaperReunioes elevation={3}>
      <ThemeProvider theme={createTheme({}, ptBR)}>
        <DataGrid
          rows={reunioes}
          columns={columns({
            open,
            setOpen,
            loadingSave,
            setLoadingSave,
            setReuniao,
          })}
          sx={{
            borderRadius: '10px',
            minHeight: '700px',
            height: 'auto',
          }}
        />
      </ThemeProvider>
      {reuniao && <DrawerConcluir
        reuniao={reuniao}
        open={open}
        setOpen={setOpen}
        loadingSave={loadingSave}
        setLoadingSave={setLoadingSave}
      />}
    </PaperReunioes>
  );
};

export const PaperReunioes = styled(Paper)`
  padding: 30px;
  border-radius: 10px;
  margin: 20px;
  width: 100%;
`;

export default Reunioes;
