import { useAxiosWrapper } from '../../services/axiosWrapper';
import { useBaseActions } from '../base.actions';
import { competenciaAtom } from './CompetenciaState';
import { authAtom } from '../usuarios/AuthState';
import { history } from '../../helpers/history';
import { RecoilState, useSetRecoilState } from 'recoil';

function useCompetenciaActions() {
  const baseActions = useBaseActions('configCompetencias', competenciaAtom);
  const axiosWrapper = useAxiosWrapper();
  const setAuth = useSetRecoilState(authAtom);

  return {
    list,
  };

  async function list(salvarNoState: boolean = false, query: string = '') {
    let url = `${process.env.REACT_APP_API_URL}/v1/configCompetencias`;
    if (query !== '') url += query;

    try {
      const recursos = await axiosWrapper.get(url);
      return recursos;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
    
  }
}

export { useCompetenciaActions };
