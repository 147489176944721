import { RecoilState, useSetRecoilState } from 'recoil';
import { useAxiosWrapper } from '../../services/axiosWrapper';
import { authAtom } from '../usuarios/AuthState';
import { history } from '../../helpers/history';

function usePerfilActions() {
  const axiosWrapper = useAxiosWrapper();
  const setAuth = useSetRecoilState(authAtom);

  return {
    list
  };

  async function list(salvarNoState: boolean = false, query: string = '') {
    let url = `${process.env.REACT_APP_API_URL}/v1/perfis`;
    if (query !== '') url += query;

    try {
      const recursos = await axiosWrapper.get(url);
      return recursos;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
    
  }

}

export { usePerfilActions };
