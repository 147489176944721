import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import { cardReuniao } from './cardReuniao';
import TableReunioes from './tableReunioes';
import { IReuniao, StatusReuniaoEnum } from '../../commons/types/crm';
import styled from 'styled-components';
import { useReuniaoActions } from '../../states/reunioes/reuniao.actions';
import { toast } from 'react-toastify';

const Dashboard: React.FC = () => {
  const reuniaoActions = useReuniaoActions();
  const [reunioes, setReunioes] = useState<IReuniao[]>([]);
  const [reunioesRealizadas, setReunioesRealizadas] = useState<IReuniao[]>([]);
  const [reunioesProximas, setReunioesProximas] = useState<IReuniao[]>([]);
  const [reunioesAtrasadas, setReunioesAtrasadas] = useState<IReuniao[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reuniaoActions
      .list()
      .then((resp: any) => setReunioes(resp.resultado))
      .catch((e) => toast.error(e))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setReunioesRealizadas(
      reunioes.filter(
        (reuniao) =>
          reuniao?.status === StatusReuniaoEnum.PENDENTE_APROVACAO ||
          reuniao?.status === StatusReuniaoEnum.APROVADA
      )
    );
    setReunioesProximas(
      reunioes.filter(
        (reuniao) =>
          reuniao?.status === StatusReuniaoEnum.AGENDADA &&
          new Date(reuniao?.dtPrevista as Date) >= new Date()
      )
    );
    setReunioesAtrasadas(
      reunioes.filter(
        (reuniao) =>
          reuniao?.status === StatusReuniaoEnum.AGENDADA &&
          new Date(reuniao?.dtPrevista as Date) <= new Date()
      )
    );
  }, [reunioes]);

  return (
    <>
      <GridDashboard container gap={2}>
        {cardReuniao(
          EventAvailableIcon,
          'Últimas Reuniões',
          reunioesRealizadas,
          loading
        )}
        {cardReuniao(ListAltRoundedIcon, 'Próximas Reuniões', reunioesProximas, loading)}
        {cardReuniao(
          ReportGmailerrorredRoundedIcon,
          'Reuniões Atrasadas',
          reunioesAtrasadas,
          loading
        )}

        <Grid item xs={12} sx={{ height: '400px' }}>
          <TableReunioes reunioes={reunioes} loading={loading} />
        </Grid>
      </GridDashboard>
    </>
  );
};

const GridDashboard = styled(Grid)`
  margin: 40px 20px;
  justify-content: space-between;
`;

export default Dashboard;
