import { Box, Grid, Typography, Icon } from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridEventListener,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useRecoilValue } from "recoil";
import { styled } from "styled-components";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  IComanda,
  IComandaEtapaReunioes,
  IEtapa,
  IReuniao,
  StatusReuniaoEnum,
} from "../../../../../commons/types/crm";
import { authAtom } from "../../../../../states/usuarios/AuthState";
import { useUsuarioActions } from "../../../../../states/usuarios/usuario.actions";
import { IAuth } from "../../../../../commons/types/usuatios";
import { ButtonStato } from "../../../../../commons/styleds/buttons";
import DrawerReunioes from "./DrawerReunioes";
import { useReuniaoActions } from "../../../../../states/reunioes/reuniao.actions";
import { handleStatus } from "../../../../Dashboard/tableReunioes";
import DrawerConcluir from "../../../../Dashboard/Components/DrawerConcluir";
import { useLocation } from "react-router-dom";
import { ptBR } from "@mui/material/locale";
dayjs.extend(utc);
dayjs.extend(timezone);

const empty: IComandaEtapaReunioes = {
  id: 0,
  tbEtapaId: 0,
  onboarding: false,
  dtRealizadaInicio: undefined,
  dtRealizadaFim: undefined,
  idConsultorResponsavel: 0,
  valorHora: undefined,
  tempoTotal: "",
  valorTotal: "",
} as IComandaEtapaReunioes;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface IParams {
  idComanda?: number;
  etapas?: IEtapa[];
  comandaEtapas?: IComandaEtapaReunioes[];
  comanda?: IComanda;
  loading: boolean;
}

const ReuniaoComponent: React.FC<IParams> = (props) => {
  const { idComanda, etapas, comandaEtapas, comanda, loading } = props;
  const auth = useRecoilValue(authAtom);
  const consultorActions = useUsuarioActions();
  const reunioesActions = useReuniaoActions();
  const [open, setOpen] = useState<boolean>(false);
  const [editando, setEditando] = useState<boolean>(false);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [reunioes, setReunioes] = useState<IComandaEtapaReunioes[]>([]);
  const [etapasSelect, setEtapasSelect] = useState<IEtapa[]>([]);
  const [reuniao, setReuniao] = useState<IComandaEtapaReunioes>(empty);
  const [consultores, setConsultores] = useState<IAuth[]>([]);
  const reuniaoActions = useReuniaoActions();
  const query = useQuery();
  const reuniaoId = query.get("reuniao");

  useEffect(() => {
    const comandaEtapaList = comandaEtapas?.filter(
      // (f) => f.idConsultorResponsavel! > 0 && !f.onboarding,
      (f) => f.idConsultorResponsavel! > 0,
    );
    setReunioes(comandaEtapaList!);

    const listEtapas = etapas?.filter(
      (f) => f.nome?.toLowerCase() !== "onboarding",
    )!;
    const llistEtapas = listEtapas?.filter(
      (f) =>
        !comandaEtapaList?.some(
          (s) => s.tbEtapaId === f.id && s.idConsultorResponsavel! > 0,
        ),
    );
    setEtapasSelect(llistEtapas);

    consultorActions
      .list()
      .then((resp: any) => {
        setConsultores(resp.resultado);
      })
      .catch((err: any) => toast.warn(err));

    if (reuniaoId) {
      const reuniaoSelecionada = comandaEtapaList?.find(
        (e) => e.id === Number(reuniaoId),
      );
      if (reuniaoSelecionada)
        handleRowClick(
          {
            row: reuniaoSelecionada,
          } as any,
          {} as any,
          {} as any,
        );
    }
  }, [comanda, comandaEtapas, etapas]);

  const columns: GridColDef[] = [
    {
      field: "idEtapa",
      headerName: "Reunião",
      width: 300,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        const etapaFind = etapas?.find((f) => f.id === obj.tbEtapaId);
        console.log(etapaFind);
        if (etapaFind)
          return etapaFind?.nome;
        return obj.onboarding ? 'Onboarding' : '';
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 140,
      flex: 150,
      valueGetter(params: any) {
        return handleStatus(params);
      },
    },
    {
      field: "dtPrevista",
      headerName: "Data Prevista",
      width: 120,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        return new Date(obj.dtPrevista! as Date).toLocaleDateString();
      },
    },
    {
      field: "dtRealizadaInicio",
      headerName: "Hora Inicio",
      width: 120,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        return new Date(obj.dtRealizadaInicio! as Date).toLocaleTimeString();
      },
    },
    {
      field: "dtRealizadaFim",
      headerName: "Hora Fim",
      width: 120,
      editable: false,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        return new Date(obj.dtRealizadaFim! as Date).toLocaleTimeString();
      },
    },
    {
      field: "idConsultorResponsavel",
      headerName: "Consultor (a):",
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        return consultores?.find((f) => f.id === obj.idConsultorResponsavel)
          ?.nome;
      },
    },
    {
      field: "tempoTotal",
      headerName: "Tempo Total",
      width: 120,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        return getTempoTotal(
          new Date(obj.dtRealizadaInicio!.toString()).toLocaleTimeString(),
          new Date(obj.dtRealizadaFim!.toString()).toLocaleTimeString(),
        );
      },
    },
    {
      field: "acao",
      headerName: "Ação",
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IComandaEtapaReunioes;
        return (
          <Icon
            sx={{ cursor: "pointer" }}
            onClick={() => HandleApagar(obj.id!)}
          >
            {" "}
            <DeleteIcon />{" "}
          </Icon>
        );
      },
    },
  ];

  const getTempoTotal = (horaInicio: string, horaFim: string) => {
    let horaTotal = "";
    // duração de uma jornada normal de trabalho (em minutos)
    let hInicio = parse(horaInicio);
    // quantidade de minutos efetivamente trabalhados
    let hFim = parse(horaFim);

    // diferença entre as jornadas
    let diff = Math.abs(hFim - hInicio);
    if (diff !== 0) {
      let horas = Math.floor(diff / 60);
      let minutos = diff - horas * 60;
      horaTotal = horas + ":" + (minutos > 9 ? minutos : "0" + minutos);
    }

    return horaTotal;
  };

  const getValorTotal = (
    horaInicio: string,
    horaFim: string,
    valorHora: string,
  ) => {
    let valorTotal = "";

    // duração de uma jornada normal de trabalho (em minutos)
    let hInicio = parse(horaInicio);
    // quantidade de minutos efetivamente trabalhados
    let hFim = parse(horaFim);

    // diferença entre as jornadas
    let diff = Math.abs(hFim - hInicio);
    if (diff !== 0) {
      let hValor = valorHora === "" ? "0" : valorHora;
      let valor = parseFloat(hValor) * (diff / 60);

      valorTotal = "R$ " + valor.toFixed(2);
    }

    return valorTotal;
  };

  function parse(horario: any) {
    // divide a string em duas partes, separado por dois-pontos, e transforma em número
    let [hora, minuto] = horario.split(":").map((v: any) => parseInt(v));
    if (!minuto) {
      // para o caso de não ter os minutos
      minuto = 0;
    }
    return minuto + hora * 60;
  }

  const handleRowClick: GridEventListener<"rowClick"> = (params: any) => {
    const listEtapas = etapas?.filter(
      (f) => f.nome?.toLowerCase() !== "onboarding",
    )!;
    setEtapasSelect(listEtapas);
    openDrawerReuniao(true);
    setEditando(true);
    setReuniao({
      ...reuniao,
      id: params.row.id,
      tbEtapaId: params.row.tbEtapaId,
      comandaId: params.row.comandaId,
      dtPrevista: dayjs.tz(params.row.dtPrevista),
      dtRealizadaInicio: dayjs.tz(params.row.dtRealizadaInicio),
      dtRealizadaFim: dayjs.tz(params.row.dtRealizadaFim),
      onboarding: params.row.onboarding,
      idConsultorResponsavel: params.row.idConsultorResponsavel,
      valorHora: params.row.valorHora,
      tempoTotal: params.row.tempoTotal,
      valorTotal: params.row.valorTotal,
    });
  };

  const openDrawerReuniao = (result: any) => {
    setReuniao(empty);
    const listEtapas = etapas?.filter(
      (f) => f.nome?.toLowerCase() !== "onboarding",
    )!;
    const comandaEtapaList = comandaEtapas?.filter(
      (f) => f.idConsultorResponsavel! > 0 && !f.onboarding,
    );

    const llistEtapas = listEtapas?.filter(
      (f) =>
        !comandaEtapaList?.some(
          (s) => s.tbEtapaId === f.id && s.idConsultorResponsavel! > 0,
        ),
    );
    setEtapasSelect(llistEtapas);

    const etapasComMetodologia = etapas?.map((e) => {
      return {
        id: e.id,
        nome: `${e.idMetodologiaNavigation?.nome
          ? e.idMetodologiaNavigation?.nome + " | "
          : +" | "
          } ${e.nome}`,
      };
    });
    setEtapasSelect(etapasComMetodologia! as IEtapa[]);

    setOpen(result);
    if (!result) setEditando(false);
  };

  const HandleApagar = (id: any) => {
    const sub = reunioes.filter((f) => f.id !== id);
    setReunioes(sub);
  };

  const adicionarNaLista = () => {
    setLoadingSave(true);

    if (validarReunião()) {
      toast.warn("Preencha todos os campos para continuar");
      setLoadingSave(false);
      return;
    }

    let horaInicio = parse(
      new Date(reuniao.dtRealizadaInicio!.toString()).toLocaleTimeString(),
    );
    let horaFim = parse(
      new Date(reuniao.dtRealizadaFim!.toString()).toLocaleTimeString(),
    );

    if (horaInicio < horaFim) {
      if (reuniao?.id === 0) {
        reunioesActions
          .create(reuniao)
          .then((result) => {
            toast.success('Reunião adicionada');
            const newList = reunioes.concat(result);
            setReunioes(newList);
            openDrawerReuniao(false);
            setReuniao(empty);
          })
          .catch(() => toast.error('Falha ao adicionar Reunião'));
      } else {
        reunioesActions
          .update(reuniao!.id!, reuniao)
          .then((result) => {
            const sub = reunioes.filter((f) => f.id !== result.id);
            setReunioes(sub.concat(result));
            setReuniao(empty);
            openDrawerReuniao(false);
            toast.success("Atualizado com sucesso");
          })
          .catch(() => { });
      }
    } else {
      toast.warn("Hora Inicio não pode ser maior que a hora fim!");
    }

    setLoadingSave(false);
  };

  const validarReunião = () => {
    if (reuniao.dtPrevista === undefined) return true;
    if (reuniao.dtRealizadaInicio === undefined) return true;
    if (reuniao.dtRealizadaFim === undefined) return true;
    if (reuniao.tbEtapaId === undefined) return true;
    if (reuniao.idConsultorResponsavel === undefined) return true;
    // if (reuniao.valorHora === undefined || reuniao.valorHora === 0) return true;
  };

  const patchReuniao = (status: StatusReuniaoEnum) => {
    const dataInicio = new Date((reuniao.dtPrevista as any).toDate());
    dataInicio.setHours((reuniao.dtRealizadaInicio as any).hour());
    dataInicio.setMinutes((reuniao.dtRealizadaInicio as any).minute());

    const dataFim = new Date((reuniao.dtPrevista as any).toDate());
    dataFim.setHours((reuniao.dtRealizadaFim as any).hour());
    dataFim.setMinutes((reuniao.dtRealizadaInicio as any).minute());

    reuniaoActions
      .patch(reuniao?.id!, {
        id: reuniao?.id,
        dataInicio,
        dataFim,
        status,
      })
      .then(() => toast.success("Revisão solicitada com sucesso."))
      .catch((e) => toast.error(e ?? "Falha ao concluir reunião."));
  };

  const revisarReuniao = (comando: string) => {
    setOpen(false);
    switch (comando) {
      case "concluir":
        patchReuniao(StatusReuniaoEnum.PENDENTE_APROVACAO);
        break;
      case "noShow":
        patchReuniao(StatusReuniaoEnum.NO_SHOW);
        break;
      default:
        break;
    }
  };

  const getReuniao = () => {
    const reuniaoInterface: IReuniao = {
      id: reuniao.id,
      ativo: reuniao.ativo,
      status: reuniao.status,
      nomeAssessorado: comanda?.contato?.nome!,
      idAssessorado: comanda?.contato?.id!,
      nomeConsultor: reuniao.consultorResponsavel?.nome ?? "",
      idConsultorMaster: reuniao.consultorResponsavel?.id ?? 0,
      dtRealizadaFim: reuniao.dtRealizadaFim as any,
      dtRealizadaInicio: reuniao.dtRealizadaInicio as any,
      dtPrevista: reuniao.dtPrevista as any,
    } as IReuniao;
    return reuniaoInterface;
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: "250px",
            width: "100%",
          }}
        >
          <Typography variant="subtitle1" sx={{ padding: "10px" }}>
            Responsáveis pelas reuniões:
          </Typography>
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) =>
                `${row?.tbEtapaId}_${row?.comandaId}_${Math.floor(
                  Math.random() * 20,
                )}`
              }
              rows={reunioes ?? []}
              onRowClick={handleRowClick}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: "white",
                border: "1px solid black",
                borderRadius: "14px",
              }}
            />
          </ThemeProvider>

          <GridItemCadastroBtnSalvar
            item
            xs={11}
            md={11}
            sx={{
              position: "relative",
              bottom: "34%",
              right: "6%",
              zIndex: "30",
              left: "8%",
            }}
          >
            <ButtonStato
              style={{
                padding: "0px",
                height: "35px",
                minWidth: "35px",
              }}
              onClick={() => openDrawerReuniao(true)}
              variant="contained"
            >
              +
            </ButtonStato>
          </GridItemCadastroBtnSalvar>

          <DrawerReunioes
            adicionarNaLista={adicionarNaLista}
            consultores={consultores}
            editando={editando}
            setEditando={setEditando}
            etapasSelect={etapasSelect}
            idComanda={idComanda!}
            loadingSave={loadingSave}
            open={open}
            reuniao={reuniao}
            setOpen={setOpen}
            setReuniao={setReuniao}
            revisarReuniao={revisarReuniao}
          />
        </Box>
      </Grid>
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default ReuniaoComponent;
