import { useBaseRootActions } from "../../../baseRoot.actions";
import { anexoAtom } from "./AnexoState";

function useAnexosActions() {
  const baseActions = useBaseRootActions('anexos', anexoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    createFormData: baseActions.createFormData,
  };
}

export { useAnexosActions };

