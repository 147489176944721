import { Dayjs } from "dayjs";
import { IBaseEntity, IIdNome } from "./base";
import { IAuth } from "./usuatios";

export enum StatusReuniaoEnum {
  PENDENTE = "PENDENTE",
  AGENDADA = "AGENDADA",
  PENDENTE_APROVACAO = "PENDENTE_APROVACAO",
  APROVADA = "APROVADA",
  CANCELADA = "CANCELADA",
  NO_SHOW = "NO_SHOW",
}

export enum StatusValidacaoEnum {
  PENDENTE = 'PENDENTE',
  APROVADO = 'APROVADO',
  REPROVADO = 'REPROVADO',
}

export interface IComandaEtapaReunioes extends IBaseEntity {
  comandaId: number;
  tbEtapaId: number;
  dtPrevista?: Date | Dayjs;
  dtRealizadaInicio?: Date | Dayjs;
  dtRealizadaFim?: Date | Dayjs;
  valorHora?: number;
  etapaExecutivo: string | null;
  idConsultorResponsavel: number;
  consultorResponsavel?: IAuth;
  idUsuarioCadastro: number;
  idUsuarioAlteracao: string | null;
  onboarding: boolean;
  status: StatusReuniaoEnum;
  tempoTotal?: string;
  valorTotal?: string;
  statusValidacao?: StatusValidacaoEnum;
}

export interface IReuniao extends IBaseEntity {
  nomeAssessorado: string;
  idAssessorado: number;
  nomeConsultor: string;
  idConsultor: number;
  nomeConsultorMaster: string;
  idConsultorMaster: number;
  idComanda: number;
  nomeEtapa: string;
  dtPrevista?: Date;
  dtRealizadaInicio?: Date;
  dtRealizadaFim?: Date;
  valorHora?: number;
  onboarding: boolean;
  status: StatusReuniaoEnum;
  tbEtapaId: number;
  tempoTotal?: string;
  valorTotal?: string;
}

export interface IStatus extends IBaseEntity {
  nome?: string;
}

export interface IContato extends IBaseEntity {
  nome: string;
  email: string;
  cpf?: string;
}

export interface IMetodologia extends IBaseEntity {
  nome?: string;
  texto?: string;
}

export interface IEtapa extends IBaseEntity {
  nome?: string;
  texto?: string;
  idPrazo?: string;
  ordem?: number;
  idUsuario?: string;
  imagem?: string;
  idMetodologia?: string;
  objetoForms?: string;
  idMetodologiaNavigation?: IMetodologia;
}

export interface IComanda extends IBaseEntity {
  dataInicio?: string;
  dataFim?: string;
  bUs?: string;
  idStatus?: number;
  status?: IStatus;
  contato?: IContato;
  etapas?: IEtapa[];
  comandaEtapaReunioes?: IComandaEtapaReunioes[];
  plano?: string;
  modeloComercial?: string;
  projetoConfidencial: boolean;
  tbEmpresaId?: number;
  idConsultorOnboarding?: number;
  valorHora: number | undefined;
  comercialResponsavel?: string;
  empresaCliente?: string;
  rhRepresentante?: string;
  estimativaAdesao?: string;
  bonusSalarios: number;
  custoProjeto: number;
  ultimaEmpresaTrabalho?: string;
  segmento?: string;
  regiaoMoradia?: string;
  regiaoRestricaoTrabalho?: string;
  modeloTrabalho: string;
  restricaoEmpresa: boolean;
  empresaDaRestricao?: string;
  restricaoSegmento: boolean;
  segmentoDaRestricao?: string;
  modeloContrato?: string;
  recolocadoIndependeteFonte?: boolean;
  recolocadoStato?: boolean;
  contatoRelatorio?: string;
  planejamento?: boolean;
  relacionamentoMercado?: boolean;
  coachingIntegração?: boolean;
  dataExtensao?: Date;
}

export interface ICv extends IIdNome {
  idContato?: number;
  idIdioma?: number;
  resumo?: string;
  idioma?: string;
  objetivo?: string;
  cartaApres?: string;
  infoAdicional?: string;
  anexo?: any;
  versao?: number;
  tipo?: number;
  excluido?: number;
  arquivo?: number;
  nomeCv?: string;
  dtCadastro?: string;
  ativo?: boolean;
}

export interface IPerfil {
  id: number;
  nome: string;
  ativo: boolean;
}

export interface IAnalisePerfil {
  id: number;
  contatoId: number;
  informacaoGeralP1?: string;
  informacaoGeralP2?: string;
  idFaixaRemuneracao?: number;
  informacaoGeralP3?: string;
  informacaoGeralP4?: string;
  informacaoGeralP5?: string;
  informacaoGeralP6?: string;
  informacaoGeralP7?: string;
  informacaoGeralP8?: string;
  informacaoGeralP9?: string;
  informacaoGeralP10?: string;
  informacaoGeralP11?: string;
  informacaoGeralP12?: string;
  referencia?: string;
  resultadosRealizacoesCompetencias?: any[];
  analiseSwotForteP1?: string;
  analiseSwotForteP2?: string;
  analiseSwotForteP3?: string;
  analiseSwotForteP4?: string;
  analiseSwotForteP5?: string;
  analiseSwotForteP6?: string;
  analiseSwotForteP7?: string;
  analiseSwotFracoP1?: string;
  analiseSwotFracoP2?: string;
  analiseSwotFracoP3?: string;
  analiseSwotFracoP4?: string;
  analiseSwotFracoP5?: string;
  analiseSwotFracoP6?: string;
  analiseSwotFracoP7?: string;
  analiseSwotFracoP8?: string;
  analiseSwotOportunidadeP1?: string;
  analiseSwotOportunidadeP2?: string;
  analiseSwotOportunidadeP3?: string;
  analiseSwotOportunidadeP4?: string;
  analiseSwotOportunidadeP5?: string;
  analiseSwotAmeacaP1?: string;
  analiseSwotAmeacaP2?: string;
  analiseSwotAmeacaP3?: string;
  analiseSwotAmeacaP4?: string;
  analiseSwotAmeacaP5?: string;
  analiseSwotAmeacaP6?: string;
  analiseSwotForcaOrigem?: string;
  analiseSwotOportunidadeOrigem?: string;
  analiseSwotFraquezaOrigem?: string;
  analiseSwotAmeacaOrigem?: string;
  reflexaoDeCarreiraP1?: string;
  reflexaoDeCarreiraP2?: string;
  reflexaoDeCarreiraP3?: string;
  reflexaoDeCarreiraP4?: string;
  reflexaoDeCarreiraP5?: string;
  reflexaoDeCarreiraP6?: string;
  reflexaoDeCarreiraP7?: string;
  reflexaoDeCarreiraP8?: string;
  reflexaoDeCarreiraP9?: string;
  estiloDeLiderancaP1?: string;
  estiloDeLiderancaP2?: string;
  estiloDeLiderancaP3?: string
}

export interface IResultadoRealizacaoCompetencia {
  id?: number;
  IdContatoPerfilPlanejamento?: number;
  problemaDesafio?: string;
  acao?: string;
  resultado?: string;
  sintese?: string;
  tbCompetencias?: ICompetencia[];
  idCompetencias?: number[];
  ativo?: boolean;
}

export interface ICompetencia {
  id: number;
  descricao?: string;
  ativo?: boolean
}


