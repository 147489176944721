import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { FormControlStato, GridContainer } from "../../commons/styleds/styleds";
import { GridItem } from "../../components/Menu";
import { history } from "../../helpers/history";
import { useRedefinirSenhaActions } from "../../states/redefinirSenha/redefinirSenha.actions";
import Logo from './../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-1.png';

const SolicitarRedefinicaoSenha: React.FC = () => {
  const redefinirSenhaActions = useRedefinirSenhaActions();
  const [email, setEmail] = useState<string>('');
  const [enviado, setEnviado] = useState<boolean>(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    redefinirSenhaActions.create({ email, origem: 'CONSULTOR' }).then((resp: any) => {
      toast.success('Enviado com sucesso.');
      setEnviado(true);
    });
  };

  return (
    <GridContainer container sx={{ height: '100vh !important' }}>
      <GridItem item xs={12} md={6}>
        <img src={Logo} alt="logo" width={'80%'} />
      </GridItem>
      <GridItem item xs={12} md={6} color={'var(--blue2)'}>
        <Grid item xs={10} md={9}>
          <form onSubmit={onSubmit}>
            <GridItem item xs={12} md={12} color={'var(--blue2)'}>
              <TitleLogin>Redefinição de senha</TitleLogin>
              <FormControlStato sx={{ width: '100%', marginBottom: '15px' }} variant="filled">
                <TextField
                  id="filled-basic"
                  label="E-mail"
                  type="email"
                  required
                  variant="filled"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                />
              </FormControlStato>
              {enviado && (
                <Typography variant="body1" color="var(--green3)">
                  Verifique sua caixa de entrada para obter o link de redefinição de senha.
                </Typography>
              )}
            </GridItem>
            <GridItem item xs={12} md={12} color={'var(--blue2)'} style={{ paddingTop: '15px' }}>
              <Grid style={{ textAlign: 'right', width: '100%' }}>
                <Button
                  variant="outlined"
                  style={{ marginRight: '15px' }}
                  onClick={() => history.goBack()}
                >
                  Cancelar
                </Button>
                <Button type="submit" variant="contained">
                  Enviar
                </Button>
              </Grid>
            </GridItem>
          </form>
        </Grid>
      </GridItem>
    </GridContainer>
  );
};

export const TitleLogin = styled.h3`
  font-family: "Arial Narrow Bold";
  font-size: 26px;
  font-weight: 500;
  color: white;
  text-align: center;
  @media (max-width: 900px) {
    color: var(--black);
  }
`;

export default SolicitarRedefinicaoSenha;
