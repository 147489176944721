import { Box, createTheme, Grid, ThemeProvider, Typography } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { PaperRelatorio } from '..';
import { IComandaEtapaReunioes, StatusValidacaoEnum } from '../../../../commons/types/crm';

const formatCurrency = (value: any) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

interface ITimesheetResultado {
  dataInicio: Date;
  dataFim: Date;
  resultado: IComandaEtapaReunioes[];
}

const TimesheetRestultado: React.FC<ITimesheetResultado> = (props) => {
  const { dataInicio, dataFim, resultado } = props;
  const [reunioes, setReunioes] = useState<IComandaEtapaReunioes[]>(resultado ?? []);
  const [totalHoras, setTotalHoras] = useState(0);
  const [totalReceber, setTotalReceber] = useState(0);

  useEffect(() => {
    let ttReceber = 0;
    let ttHoras = reunioes.reduce((total, item) => {
      const inicio = moment(item.dtRealizadaInicio as any);
      const fim = moment(item.dtRealizadaFim as any);
      const duration = moment.duration(fim.diff(inicio));
      const horas = duration.asHours();

      if (item.statusValidacao === StatusValidacaoEnum.APROVADO)
        ttReceber += item.valorHora! * horas;

      return total + horas;
    }, 0)

    setTotalHoras(ttHoras);
    setTotalReceber(ttReceber);
  }, [reunioes, resultado])

  const columns: GridColDef[] = [
    {
      field: 'unidade',
      headerName: 'Unidade',
      width: 70,
      valueGetter: (params: GridCellParams, row: any) => {
        const data = row as any;
        return data.comandaIdNavigation.modeloComercial;
      },
    },
    {
      field: 'nome',
      headerName: 'Assessorado',
      width: 160,
      valueGetter: (params: GridCellParams, row: any) => {
        const data = row as any;
        return data.comandaIdNavigation.contato.nome;
      },
    },
    {
      field: 'tbEtapaIdNavigation.nome',
      headerName: 'Etapa',
      width: 160,
      valueGetter: (params: GridCellParams, row: any) => {
        const data = row as any;
        return data.tbEtapaIdNavigation.nome;
      },
    },
    {
      field: 'dtPrevista',
      headerName: 'Data',
      width: 110,
      valueGetter: (params: any, row: any) => {
        const data = params as Date;
        return moment(data).format('DD/MM/YYYY');
      },
    },
    {
      field: 'dtRealizadaInicio',
      headerName: 'Inicio',
      width: 80,
      valueGetter: (params: any) => {
        const data = params as Date;
        return moment(data).format('HH:mm');
      },
    },
    {
      field: 'dtRealizadaFim',
      headerName: 'Fim',
      width: 60,
      valueGetter: (params: any) => {
        const data = params as Date;
        return moment(data).format('HH:mm');
      },
    },
    {
      field: 'horasConsumidas',
      headerName: 'Horas consumidas',
      width: 90,
      valueGetter: (params: GridCellParams, row: any) => {
        const data = row as any;
        const inicio = moment(data.dtRealizadaInicio);
        const fim = moment(data.dtRealizadaFim);
        const duration = moment.duration(fim.diff(inicio));
        const horas = duration.asHours();
        return `${horas} hrs`;
      },
    },
    {
      field: 'valorPago',
      headerName: 'Valor Pago',
      width: 90,
      valueGetter: (params: GridCellParams, row: any) => {
        const data = row as any;
        const inicio = moment(data.dtRealizadaInicio);
        const fim = moment(data.dtRealizadaFim);
        const duration = moment.duration(fim.diff(inicio));
        const horas = duration.asHours();

        const valor = data.valorHora * horas;
        return `R$ ${valor}`;
      },
    },
    {
      field: 'bu',
      headerName: 'BU',
      width: 110,
      valueGetter: (params: GridCellParams, row: any) => {
        const data = row as any;
        return data.comandaIdNavigation.bUs;
      },
    },
    {
      field: 'statusValidacao',
      headerName: 'Contabilizar/Validar',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => {
        const statusValidacao = params.row.statusValidacao as StatusValidacaoEnum;

        if (statusValidacao === StatusValidacaoEnum.APROVADO)
          return <span style={{ color: 'green' }}>{statusValidacao}</span>;
        else if (statusValidacao === StatusValidacaoEnum.REPROVADO)
          return <span style={{ color: 'red' }}>{statusValidacao}</span>;
        return <>{statusValidacao}</>;

      },
    },
  ];

  return (
    <PaperRelatorio sx={{ margin: '30px 0', height: '100%' }} elevation={3}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="h3">Resumo:</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Data início:</b>
            </Typography>
            <Typography variant="body2">
              {new Date(dataInicio).toLocaleDateString('pt-BR')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Data fim:</b>
            </Typography>
            <Typography variant="body2">{new Date(dataFim).toLocaleDateString('pt-BR')}</Typography>
          </Grid>

          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Total Horas:</b>
            </Typography>
            <Typography variant="body2">{totalHoras} hrs</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Total a Receber:</b>
            </Typography>
            <Typography variant="body2">{formatCurrency(totalReceber)}</Typography>
          </Grid>
        </Grid>

        <ThemeProvider theme={createTheme({}, ptBR)}>
          <DataGrid
            rows={reunioes}
            columns={columns}
            sx={{
              backgroundColor: 'white',
              borderRadius: '20px',
              height: '500px',
              marginTop: '15px',
            }}
          />
        </ThemeProvider>
      </Box>
    </PaperRelatorio>
  );
};

const SpanValidar = styled.span`
  font-size: 12px;
  cursor: pointer;
`;

export default TimesheetRestultado;
